import React from 'react'
import { Form } from 'react-bootstrap'
import DuplicateTitle from '../Forms/DuplicateTitle'
import TitleAlter from '../Forms/TitleAlter'

export default function TitleTab() {
  return (
    <>
        <Form.Label>DUPLICATE TITLE</Form.Label>
        <DuplicateTitle/>
        <br/>
        <Form.Label>TITLE ALTERATION</Form.Label>
        <TitleAlter/>       
    </>
  )
}