import { useState ,useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { PDFDocument } from 'pdf-lib';


function ReplacementLic() {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({basicInfo: '', address: '',});
  const [loading, setLoading] = useState(false); // Added loading state
  const [pdfResponse, setPdfResponse] = useState(null);

 useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch('https://raw.githubusercontent.com/UNSORTEDTHINGS/pdf/main/VL040RL.pdf');
      const pdfBuffer = await response.arrayBuffer();
      setPdfResponse(pdfBuffer);
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };

  fetchData();
}, []);

const generate = async () => {
  setLoading(true); // Set loading to true before generating PDF
  const licdata = formData.basicInfo.split(' ').filter((element) => element !== '');
  const [name,dob,gender, weight, height,eyeColor, licenseNumber] = licdata;
  try {
    const pdfDoc = await PDFDocument.load(pdfResponse);   
    console.log(gender)
    pdfDoc.getForm().getTextField('Name Last First Middle.0').setText(name.split(',')[0]);
    pdfDoc.getForm().getTextField('Name Last First Middle.1.0').setText(name.split(',')[1]);
    pdfDoc.getForm().getTextField('Name Last First Middle.1.1').setText(name.split(',')[2]);
    pdfDoc.getForm().getTextField('Text1').setText(decodeEyes(eyeColor));
    pdfDoc.getForm().getTextField('Height').setText(formatHeight(height));
    pdfDoc.getForm().getTextField('Weight').setText(`${weight} LB`);
    pdfDoc.getForm().getTextField('Vermont License or Permit').setText(licenseNumber);
    pdfDoc.getForm().getTextField('Date of Birth mmddyyyy').setText(formatDob(dob));

    openInNewTab(pdfDoc);
  } catch (error) {
    console.error('Error generating PDF:', error);
  }finally {
    setLoading(false); // Set loading to false after PDF is generated
  }
};

async function openInNewTab(pdfAPI){
  const filex = await pdfAPI.save();
  const docUrl = URL.createObjectURL(new Blob([filex], { type: "application/pdf" }));
  document.getElementById('pdfv').setAttribute('src',docUrl);
  window.open(document.getElementById('pdfv').src,'_blank').focus();
} 

const decodeEyes = (number) => {
  const colors = ['BROWN', 'BLUE', 'BLACK', 'BLUE', 'BROWN', 'HAZEL', 'GREY', 'MIX'];
  return colors[number] || 'BROWN';
};

const formatDob = (text) => {
  const [month, day, year] = text.split('-');
  const century = parseInt(year, 10) < 20 ? '20' : '19';
  return `${month}/${day}/${century}${year}`;
};

const formatHeight = (text) => {
  const [feet, inches] = text.split('-');
  return `${feet}'-${inches}"`;
};

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFormData((prevData) => ({...prevData,[id]: value,}));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.persist();
  
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      console.log(formData);
      generate();
    }
  
    setValidated(true);
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Form.Group as={Col} md="10" controlId="basicInfo">
          <Form.Label>BASIC INFO</Form.Label>
          <Form.Control
            required
            type="text"
            spellCheck={false}
            className="inputs"
            value={formData.basicInfo}
            onChange={handleChange}
          />
        </Form.Group>       
      </Row>
      <br />
      <Button type="submit" disabled={loading}>
        {loading ? 'Generating...' : 'GENERATE'}
      </Button>
    </Form>
  );
}

export default ReplacementLic;