import React from 'react'
import { Form } from 'react-bootstrap'
import LicForm from '../Forms/LicForm'
import ReplacementLic from '../Forms/ReplacementLic'

export default function LicenseTab() {
  return (
    <>
        <Form.Label>LICENSE FORM</Form.Label>
        <LicForm/>
        <br/>
        <Form.Label>REPLACEMENT LICENSE</Form.Label>
        <ReplacementLic/>       
    </>
  )
}
