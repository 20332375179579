import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { PDFDocument } from 'pdf-lib';
import axios from 'axios';


const API_KEY = '449a86dbf0mshce4a90484a956e8p10f30djsnb21176daef36';
const API_HOST = 'cis-vin-decoder.p.rapidapi.com';

const fetchPdfData = async () => {
  const response = await axios.get(
    'https://raw.githubusercontent.com/UNSORTEDTHINGS/pdf/main/VT014G.pdf',
    { responseType: 'arraybuffer' }
  );
  return response.data;
};

const fetchVinInfo = async (vinNumber) => {
  const options = {
    method: 'GET',
    headers: { 'X-RapidAPI-Key': API_KEY, 'X-RapidAPI-Host': API_HOST },
  };
  const response = await axios.get(
    `https://cis-vin-decoder.p.rapidapi.com/vinDecode?vin=${vinNumber}`,
    options
  );
  return response.data.data;
};

const openPdfInNewTab = async (pdfAPI) => {
  const fileData = await pdfAPI.save();
  const docUrl = URL.createObjectURL(new Blob([fileData], { type: 'application/pdf' }));
  window.open(docUrl, '_blank').focus();
};

const Gift = () => {
  const [loading, setLoading] = useState(false); // Added loading state
  const [pdfResponse, setPdfResponse] = useState(null); // Add this line
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({ vinNumber: '' });

  useEffect(() => {
    fetchPdfData().then((pdfResponse) => setPdfResponse(pdfResponse));
  }, []);

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };
  const generate = async () => {
    setLoading(true); // Set loading to true before generating PDF
    const pdfDoc = await PDFDocument.load(pdfResponse);
    const info = await fetchVinInfo(formData.vinNumber);

    if(info.BodyClass==="Truck"){info.Model ="TK"}
    if(info.BodyClass === "Sedan/Saloon"){info.BodyClass="SEDAN";}
    if(info.BodyClass ==="Sport Utility Vehicle (SUV)/Multi-Purpose Vehicle (MPV)"){info.BodyClass="SUV"}
    if(info.BodyClass==="Hatchback/Liftback/Notchback"){info.BodyClass="HATCHBACK"}
    if(info.BodyClass==="Convertible/Cabriolet"){info.BodyClass="CONVERTIBLE"}  
    

    pdfDoc.getForm().getTextField('Make').setText(info.Make);
    pdfDoc.getForm().getTextField('Model').setText(info.Model);
    pdfDoc.getForm().getTextField('Year').setText(info.ModelYear);
    pdfDoc.getForm().getTextField('VIN').setText(info.VIN);

    openPdfInNewTab(pdfDoc);
    setLoading(false); // Set loading to false after PDF is generated
  };
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      const form = event.currentTarget;
  
      if (form.checkValidity() === false) {
        event.stopPropagation();
      } else {
        await generate();
      }
  
      setValidated(true);
    };
  
    return (
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group as={Col} md="5" controlId="vinNumber">
          <Form.Label>VIN NUMBER</Form.Label>
          <Form.Control
            type="text"
            required
            spellCheck={false}
            className="inputs"
            value={formData.vinNumber}
            onChange={handleChange}
          />
        </Form.Group>
        <br />
        <Button type="submit" disabled={loading}>
          {loading ? 'Generating...' : 'GENERATE'}
        </Button>
      </Form>
    );
  };
  
  export default Gift;