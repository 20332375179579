import React from 'react'
import { Form } from 'react-bootstrap'
import Registration from '../Forms/Registration'
import DupliacteReg from '../Forms/DupliacteReg'

export default function RegistrationTab() {
  return (
    <>
        <Form.Label>REGISTRATION FORM</Form.Label>
        <Registration/>
        <br/>
        <Form.Label>REGISTRATION REPLACEMENT</Form.Label>
        <DupliacteReg/>       
    </>
  )
}
