import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Stack from 'react-bootstrap/Stack';
import React from 'react';
import Container from 'react-bootstrap/Container';
import BillOfSale from "./component/Forms/BillOfSale";
import RegistrationTab from './component/Tabs/RegistrationTab';
import LicenseTab from './component/Tabs/LicenseTab';
import TitleTab from './component/Tabs/TitleTab';
import Gift from './component/Forms/Gift';
import ReplacementPlates from './component/Forms/ReplacementPlates'


function App() {   
  return (
    <div style={{backgroundColor:'#282a36'}}>
     <Container data-bs-theme="dark">
      <br/><br/>
     <Tabs defaultActiveKey="home" transition={false} id="noanim-tab-example" className="mb-5" >
      <Tab eventKey="lic" title="LICENSE"><Stack gap={4} className="col-md-10 mx-auto"><LicenseTab/></Stack></Tab>
      <Tab eventKey="bos" title="BILL OF SALE"><Stack gap={2} className="col-md-10 mx-auto"><BillOfSale/></Stack></Tab>
      <Tab eventKey="rp" title="REPLACEMENT PLATES"><Stack gap={2} className="col-md-10 mx-auto"><ReplacementPlates/></Stack></Tab>
      <Tab eventKey="gift" title="TAX EXAMPT"><Stack gap={2} className="col-md-10 mx-auto"><Gift/></Stack></Tab>
      <Tab eventKey="title" title="TITLE"><Stack gap={2} className="col-md-10 mx-auto"><TitleTab/></Stack></Tab>     
      <Tab eventKey="reg" title="REGISTRATION"><Stack gap={2} className="col-md-10 mx-auto"><RegistrationTab/></Stack></Tab>     
    </Tabs>  
    </Container>
    </div>
  );
}

export default App;
